import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/community-portal/community-portal/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Box = makeShortcode("Box");
const List = makeShortcode("List");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Borrow Dai`}</h1>
    <p>{`DeFi lending skips the red tape and cuts out the middlemen. Borrowing Dai is easy, but borrow responsibly.`}</p>
    <p>{`There are two ways to "borrow" Dai within DeFi. Generate Dai on Oasis or use a lending platform.`}</p>
    <h2>{`Platform Risk`}</h2>
    <p>{`Be mindful of the risks associated with lending and borrowing on the blockchain. Consider a platform’s risk rating on `}<a href="https://defiscore.io/">{`DeFi Score`}</a>{` for help gauging a given platform's risk. Users are encouraged to do their own proper due diligence.`}</p>
    <Box sx={{
      padding: 4
    }} mdxType="Box">
      <h3>{`Popular Platforms`}</h3>
      <List mdxType="List">
        <Box mdxType="Box">
          <a href="https://oasis.app/borrow">Oasis Borrow</a>
          <p>{`Borrow Dai on the Maker Foundation’s platform. Lock collateral assets in the Vault smart contract to generate new Dai against the collateral’s value.`}</p>
        </Box>
        <Box mdxType="Box"> 
          <a href="https://app.aave.com/">AAVE</a>
          <p>{`Lend or borrow Dai and various other cryptocurrencies on AAVE. `}</p>
        </Box>
        <Box mdxType="Box"> 
          <a href="https://instadapp.io/compound/">Compound</a>
          <p>{`Lend or borrow Dai and various other cryptocurrencies on Compound, one of the higher rated platforms in DeFi. `}</p>
        </Box> 
        <Box mdxType="Box"> 
          <a href="https://ddex.io/">DDEX</a>
          <p>{`Lend or borrow Dai and various other cryptocurrencies on DDEX. `}</p>
        </Box> 
        <Box mdxType="Box"> 
          <a href="https://defisaver.com/">DeFi Saver</a>
          <p>{`Save with this one-stop management solution for decentralized finance protocols.`}</p>
        </Box> 
        <Box mdxType="Box">
          <a href="https://trade.dydx.exchange/balances">dYdX</a>
          <p>{`Lend or borrow Dai, ETH, USDC, or BTC on dYdX.`}</p>
        </Box> 
      </List>
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      